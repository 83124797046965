import React, { Component } from "react";
import { connect } from "react-redux";
import RightSide from "../Sides/RightSide";

class MblinkSeatController extends Component {
  constructor(props) {
    super(props);
    this.state = { };

  }

  componentDidMount() {    
  }

  render() {
    return (
      <React.Fragment>
        <div className="module ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8">
                <article className="post">
                  <div className="post-preview">
                    <img
                      src="/img/products/seatcontroller.jpg"
                      alt="article"
                      className="img-fluid"
                    />
                  </div>
                  <div className="post-wrapper">
                    <div className="post-header">
                      <h1 className="post-title">Seat Controller (VSC)</h1>
                      <ul className="post-meta">
                        <li>April 01, 2022</li>
                      </ul>
                    </div>
                    <div className="post-content">
                      <p>
                      Single seats produced for VClasses are not used for Vitos.
                      </p>
                      <p>
                      At this point, with MBlink Seat Controller, you can now enjoy S-Class comfort in your Vito by taking advantage of S-Class seats.
                      </p>

                    </div>
                    <div className="post-more pt-4 align-items-center d-flex">
                      <a href="/#" className="btn solid-btn mr-2">
                        Documentation <span className="ti-file"></span>
                      </a>                      
                    </div>
                    <div className="post-footer">
                      <div className="post-tags">
                        <a href="/#">Seats</a>
                        <a href="/#">Vclass</a>
                        <a href="/#">Mercedes</a>
                        <a href="/#">Retrofit</a>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
                            
              <RightSide/>

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state,
}))(MblinkSeatController);
