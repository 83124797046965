import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="features" className="feature-section ptb-100">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4">
                <div className="download-img d-flex align-bottom">
                  <img
                    src="img/image-10.png"
                    alt="download"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="feature-contents section-heading">
                  <h2>
                    Quick & Easy Process with <br />
                    <span>best features</span>
                  </h2>
                  <p>
                    Here are just a few examples;
                  </p>

                  <div className="feature-content-wrap">
                    <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active h6"
                          href="#tab6-1"
                          data-toggle="tab"
                        >
                          <span className="ti-palette"></span>
                          Interface
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-2"
                          data-toggle="tab"
                        >
                          <span className="ti-vector"></span>
                          AMG Menu
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-3"
                          data-toggle="tab"
                        >
                          <span className="ti-bar-chart"></span>
                          Virginization
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-4"
                          data-toggle="tab"
                        >
                          <span className="ti-announcement"></span>
                          Map Codes
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content feature-tab-content">
                      <div className="tab-pane active" id="tab6-1">
                        <div className="single-feature">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-layers rounded mr-3 icon icon-color-1"></span>
                            <h5 className="mb-0">Easy to use</h5>
                          </div>
                          <p>
                            Thanks to its ease of use, you don't need to be advanced. You can do your process by just clicking a few buttons..
                          </p>
                        </div>
                        <div className="single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-alarm-clock rounded mr-3 icon icon-color-2"></span>
                            <h5 className="mb-0">Save your time</h5>
                          </div>
                          <p>
                              Processing times are very short. For example, it only takes 3-5 seconds to activate the AMG menu of W213, W222, W205, W204, W463, W212, W172, W221 and so on.
                          </p>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab6-2">
                        <ul className="list-unstyled">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">IC213 for W213 E Class, W238 E Coupe, W257, W253FL, W463 G Class ...</p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  IC172 for W176 A Class, W117 CLA, W246 B Class, W156 GLA, W172, W166 ML/GL Class ...
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  IC222 for W222 S Class, W217 S Coupe, W205 C Class, W253 GLC Class, W470 X Class, W447 V Class ...
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  IC204 for W204 C Class, W212 E Class, W207 E Coupe, W218 CLS Class ...
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  KI221 W221 S Class, W216 CL Coupe ...
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane" id="tab6-3">
                      <ul className="list-unstyled">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">IC213 for W213 E Class, W238 E Coupe, W205FL, W257, W253FL, W463 G Class ...</p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  IC172 for W176 A Class, W117 CLA, W246 B Class, W156 GLA, W172, W166 ML/GL Class ...
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  IC222 for W222 S Class, W217 S Coupe, W205 C Class, W253 GLC Class, W470 X Class, W447 V Class ...
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  IC204 for W204 C Class, W212 E Class, W207 E Coupe, W218 CLS Class ...
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  KI221 W221 S Class, W216 CL Coupe ...
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane" id="tab6-4">
                        <ul className="list-unstyled">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">HU55 Comand for W213, W463, W205 FL</p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  HU50 and HU5s2 Comand for W222, W217, W205, W253
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  HU5s1 Comand for W212, W207, W117, W156, W166
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;
