import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSectionProducts";
import MblinkSteeringWheel from "../components/Products/MblinkSteeringWheel";
import FooterSection from "../components/Footer";
import MBlinkClimateController from "../components/Products/MBlinkClimateController";
import MblinkHdmi2Fakra from "../components/Products/MblinkHdmi2Fakra";
import MblinkSeatController from "../components/Products/MblinkSeatController";

class Products extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
          <HeroSection />
          <Switch>
            <Route exact path="/products/mblinksteeringwheel" component={MblinkSteeringWheel} />
            <Route exact path="/products/climatecontroller" component={MBlinkClimateController} />
            <Route exact path="/products/hdmi2fakra" component={MblinkHdmi2Fakra} />
            <Route exact path="/products/seatcontroller" component={MblinkSeatController} />
          </Switch>
        </div>
        <FooterSection noSubscription={true} />
      </React.Fragment>
    );
  }
}

export default Products;
