import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="about-content-left section-heading">
                  <h2>
                    MbLink is SaaS platform <br />
                    <span>to manage your car.</span>
                  </h2>

                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap d-flex align-items-center mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="img/image-icon-1.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                        You can manage your clusters, like renewing, adaptation to car and enabling AMG menu
                      </p>
                    </div>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="img/image-icon-2.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                        You can activate Carplay for Head Units, activating Head Units to your car by appliying just few click.
                      </p>
                    </div>
                    <p></p>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="img/image-icon-3.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                        Do you need update or install new navigation for your car? Here is solution, Mblink can generate installation map code for your head unit.
                        No need to wait someone, buy pin code by paypal and generate it by yourself!
                      </p>
                    </div>
                    <p></p>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="img/image-icon-4.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                        Mblink not only provides software solutions, but also provides retrofit solutions for your vehicle. Here is the simplest example of this, if you want to use the new AMG steering wheel in your old model vehicle, it is possible with the Mblink Steering Wheel Adapter!
                      </p>
                    </div>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="about-content-right d-flex justify-content-center justify-content-lg-end justify-content-md-end">
                  <img
                    src="img/image-14.png"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
