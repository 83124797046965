module.exports = {
  hero: {
    title: "Brainstorming for desired perfect Usability",
    description:
      "Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!",
    bgImage: "img/app-hero-bg.jpg"
  },
  promo: {
    title: "Why MbLink provides you to easy retrofit?",
    description:
      "Here are just a few of the biggest reasons",
    items: [
      {
        title: "Simple Design",
        description:
          "It is not like other software. Interfaces are user friendly.",
        icon: "ti-vector text-white"
      },
      {
        title: "Diagnose Tools",
        description:
          "It supports many diagnostic tools in the market and you can use it easily. You can use major Bosch VCI (aka C6), Ecom, SDConnect C4, C5, VXDiag, OpenPort2 or all Passthru devices.",
        icon: "ti-lock text-white"
      },
      {
        title: "1-Click Solution",
        description:
          "You can easily make your process with just a few clicks. For example, Renewing cluster or enabling Carplay for head units.",
        icon: "ti-eye text-white"
      }
    ]
  },
  aboutUs: {
    title: {
      bold: "Use your android or ios device",
      normal: ""
    },
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  price: {
    title: "Pricing Packages",
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
      {
        price: "400 Euro",
        license: "3 Months Membership",
        isFeatured: false,
        features: [
          "Unlimited Renewing",
          "Unlimited AMG Menu",
          "Unlimited Eprom Read/Write",
          "Unlimited Manual Coding",
          "Unlimited Enable Carplay",
          "Unlimited Enable VIM",
          "Free Airmatic Suspension Controls"
        ]
      },
      {
        price: "850 Euro",
        license: "1 Year Membership",
        isFeatured: true,
        features: [
          "Unlimited Renewing",
          "Unlimited AMG Menu",
          "Unlimited Eprom Read/Write",
          "Unlimited Manual Coding",
          "Unlimited Enable Carplay",
          "Unlimited Enable VIM",
          "Free Airmatic Suspension Controls"
        ]
      },
      {
        price: "550 Euro",
        license: "6 Months Membership",
        isFeatured: false,
        features: [
          "Unlimited Renewing",
          "Unlimited AMG Menu",
          "Unlimited Eprom Read/Write",
          "Unlimited Manual Coding",
          "Unlimited Enable Carplay",
          "Unlimited Enable VIM",
          "Free Airmatic Suspension Controls"
        ]
      }
    ]
  },
  testimonial: {
    title: "What customer say about us",
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    comments: [
      {
        name: "Arabella Ora",
        company: "AgencyCo",
        image: "img/client-2.jpg",
        comment:
          "Completely build backend ROI whereas cross-media metrics. Collaboratively deploy customer directed web-readiness via global testing procedures. Appropriately reinvent distributed innovation without proactive catalysts for change. Distinctively."
      },
      {
        name: "John Charles",
        company: "ThemeTags",
        image: "img/client-1.jpg",
        comment:
          "If you can design one thing you can design everything with Front. Just believe it. Collaboratively repurpose performance based e-commerce without cost. It's beautiful and the coding is done quickly and seamlessly."
      },
      {
        name: "Jeremy Jere",
        company: "WorldCheck",
        image: "img/client-3.jpg",
        comment:
          "The template is really nice and offers quite a large set of options. It's beautiful and the coding is done. If you can design one thing you can design everything with Front. Just believe it."
      },
      {
        name: "Ciaran Kierce",
        company: "AppCo",
        image: "img/client-4.jpg",
        comment:
          "I just wanted to share a quick note and let you know that you guys do a really good job. I’m glad I decided to work with you. It’s really great how easy your websites are to update and manage. I never have any problem at all."
      }
    ]
  },
  contact: {
    title: "Contact with us",
    description:
      "It's very easy to get in touch with us. Just use the contact form or pay us a visit for a coffee at the office. Dynamically innovate competitive technology after an expanded array of leadership.",

    office: "Head Office",
    address: "ReAL Technology, Istanbul - Turkey",
    boffice: "Branch Office",
    baddress: "ReAL Technology, Frankfurt - Germany",
    phone: "+1 (650) 772-0618",
    email: "mblinktools@gmail.com",
    skype: "astronbnx"
  },
  trustedCompany: [
    "img/client-1-color.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png"
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png"
  ],
  trustedCompanyAboutUs: {
    title: "Trusted by companies",
    description: "Rapidiously morph transparent internal or \"organic\" sources whereas resource sucking e-business. Conveniently innovate compelling internal.",
    companies: [
      "img/client-5-color.png",
      "img/client-1-color.png",
      "img/client-6-color.png",
      "img/client-3-color.png",
      "img/client-4-color.png",
      "img/client-5-color.png",
      "img/client-7-color.png",
      "img/client-2-color.png",
      "img/client-1-color.png",
      "img/client-3-color.png"
    ]
  },
  teamMember: {
    members: [
      {
        name: "Edna Mason",
        designation: "Senior Designer",
        image: "img/team-4.jpg"
      },
      {
        name: "Arabella Ora",
        designation: "Senior Designer",
        image: "img/team-1.jpg"
      },
      {
        name: "John Charles",
        designation: "Senior Designer",
        image: "img/team-2.jpg"
      },
      {
        name: "Jeremy Jere",
        designation: "Senior Designer",
        image: "img/team-3.jpg"
      },
    ]
  },
  video: {
    link: "https://youtu.be/45qE0gL05Ck"
  }
};
