import React from "react";
import { connect } from "react-redux";

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="download"
          className="video-promo ptb-100 background-img"
          style={{
            backgroundImage: "url('img/video-bg.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover"
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="video-promo-content mt-4 text-center">
                  <a
                    href="https://www.youtube.com/watch?v=45qE0gL05Ck"
                    className="popup-youtube video-play-icon d-inline-block"
                  >
                    <span className="ti-control-play"></span>{" "}
                  </a>
                  <h5 className="mt-4 text-white">Watch video overview</h5>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(Video);
