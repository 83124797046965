import React, { Component } from "react";
import { connect } from "react-redux";
import RightSide from "../Sides/RightSide";

class MblinkSteeringWheel extends Component {
  constructor(props) {
    super(props);
    this.state = { };

  }

  componentDidMount() {    
  }

  render() {
    return (
      <React.Fragment>
        <div className="module ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8">
                <article className="post">
                  <div className="post-preview">
                    <img
                      src="/img/products/mblinksteeringwheeladaptor.jpg"
                      alt="article"
                      className="img-fluid"
                    />
                  </div>
                  <div className="post-wrapper">
                    <div className="post-header">
                      <h1 className="post-title">Steering Wheel Adaptor (SWA)</h1>
                      <ul className="post-meta">
                        <li>April 01, 2022</li>
                      </ul>
                    </div>
                    <div className="post-content">
                      <p>
                        Thanks to the Mblink Steering Wheel Adapter, you get the chance to attach new type steering wheels to your old vehicles.
                      </p>
                      <p>
                        With MBlink-SWA, you can install a new type of steering wheel on your W204, W212, W207, W205, W222, W213, W238, W463, W447, W470, W211, W447, W176, W117 and similar vehicles.
                      </p>
                      <blockquote className="blockquote">
                        <img
                            src="/img/products/swtype1.jpg"
                            alt="article"
                            className="img-fluid"
                        />
                        <p>
                          AMG Steering Wheel Type 1 is used for new model cars on W463, W118, W177, W205FL etc.
                        </p>
                      </blockquote>
                      <blockquote className="blockquote">
                        <img
                            src="/img/products/swtype2.jpg"
                            alt="article"
                            className="img-fluid"
                        />
                        <p>
                          AMG Steering Wheel Type 2 is used for W223, W206 and W213FL wheels.
                        </p>
                      </blockquote>

                      <p>
                        <b>MBlink-SWA</b> implementation is quite easy. You can remove the steering wheel and then make the cable connections very simply. 
                      </p>
                      <p>  
                        <b>MBlink-SWA</b> implementation is quite easy. You can remove the steering wheel and then make the cable connections very simply. You can download Mblink Application according to the vehicle model you will install and make the necessary settings.
                      </p>

                    </div>
                    <div className="post-more pt-4 align-items-center d-flex">
                      <a href="https://mega.nz/file/T81VXQSb#7cw5LenbwEwbdNCyjvpJbC06llTpITeTer5Sqi13Vpc" target="_blank" rel="noreferrer" className="btn solid-btn mr-2">
                        Documentation <span className="ti-file"></span>
                      </a>                      
                      <a href="https://mega.nz/file/jkUXBa5Y#sYp7g1q4BCDo9B4tyiMkbhj6TsnnKOalf8iDsbqmwHQ" className="btn solid-btn" target="_blank" rel="noreferrer">
                        Mblink SWA APP<span className="ti-download"></span>
                      </a>
                    </div>
                    <div className="post-footer">
                      <div className="post-tags">
                        <a href="/#">W206</a>
                        <a href="/#">W223</a>
                        <a href="/#">W213FL</a>
                        <a href="/#">W212</a>
                        <a href="/#">W204</a>
                        <a href="/#">W207</a>
                        <a href="/#">W176</a>
                        <a href="/#">W117</a>
                        <a href="/#">W463</a>
                        <a href="/#">W213</a>
                        <a href="/#">W205</a>
                        <a href="/#">W222</a>
                        <a href="/#">W447</a>
                        <a href="/#">W470</a>
                        <a href="/#">Mercedes</a>
                        <a href="/#">Retrofit</a>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
                            
              <RightSide/>

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state,
}))(MblinkSteeringWheel);
