import React from "react";

class RightSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>

              <div className="col-lg-4 col-md-4">
                <div className="sidebar-right pl-4">
                  <aside className="widget widget-categories">
                    <div className="widget-title">
                      <h6>Categories</h6>
                    </div>
                    <ul>
                      <li>
                        <a href="/#">
                          Steering Wheel Adaptor<span className="float-right">SWA</span>
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          HDMI2Fakra Adaptor<span className="float-right">HFA</span>
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          Climate Controller <span className="float-right">VCC</span>
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          Seat Controller <span className="float-right">VSC</span>
                        </a>
                      </li>
                    </ul>
                  </aside>
                  <aside className="widget widget-tag-cloud">
                    <div className="widget-title">
                      <h6>Tags</h6>
                    </div>
                    <div className="tag-cloud">
                      <a href="/#">mercedes</a>
                      <a href="/#">W213</a>
                      <a href="/#">W463</a>
                      <a href="/#">W205</a>
                      <a href="/#">W206</a>
                      <a href="/#">W222</a>
                      <a href="/#">W223</a>
                      <a href="/#">retrofit</a>
                      <a href="/#">steering wheel</a>
                      <a href="/#">climate controller</a>
                      <a href="/#">adaptor</a>
                      <a href="/#">vito</a>
                      <a href="/#">vclass</a>
                      <a href="/#">hdmi</a>
                      <a href="/#">fakra</a>
                      <a href="/#">seats</a>
                    </div>
                  </aside>
                </div>
              </div>

      </React.Fragment>
    );
  }
}

export default RightSide;
